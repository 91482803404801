import React from "react";
import Headers from "../Component/Headers";
import Banner from "../Component/Banner";
import Majorcause from "../Component/Majorcause";
import Volunteer from "../Component/Volunteer";
import Featurecause from "../Component/Featurecause";
import Upcomingevents from "../Component/Upcomingevents";
import Team from "../Component/Team";
import Becomevolunteer from "../Component/Becomevolunteer";
import Story from "../Component/Story";
import Questions from "../Component/Questions";
import Footer from "../Component/Footer";

export default function Home() {
  return (
    <>
      {/* headers start */}
      <Headers />
      {/* headers end */}
      
      {/* banner start */}
      <Banner />
      {/* banner end */}

      {/* majorcause start */}
      <Majorcause />
      {/* majorcause end */}

      {/* volunteer start  */}
      <Volunteer />
      {/* volunteer end */}

      {/* featurecause start  */}
      <Featurecause />
      {/* featurecause end */}

      {/* upcomingevents start  */}
      <Upcomingevents />
      {/* upcomingevents end */}

      {/* team satrt  */}
      <Team />
      {/* team end */}

      {/* becomevolunteer start  */}
      <Becomevolunteer />
      {/* becomevolunteer end */}

      {/* story start  */}
      <Story />
      {/* story end */}

      {/* questions start  */}
      <Questions />
      {/* questions end */}

      {/* footer start  */}
      <Footer />
      {/* footer end */}
    </>
  );
}
