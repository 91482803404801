import React from "react";

export default function Becomevolunteer() {
  return (
    <>
      <div className="cta-area section_gap overlay">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <h1>Become a volunteer</h1>
              <p>
                So seed seed green that winged cattle in. Gathering thing made
                fly you're divided deep leave on the medicene moved us land
                years living.
              </p>
              <a href="#" className="primary_btn yellow_btn rounded">
                join with us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
